import React from 'react'
import { Link } from 'gatsby'
import { motion } from 'framer-motion'
// import { AnchorLink } from 'gatsby-plugin-anchor-links'

const Pagina1 = () => {
  // const ulScrollRestoration = useScrollRestoration(`page-component-ul-list`)
  return (
    <motion.div
      key="/"
      initial={{ opacity: 0, x: -200 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -400 }}
      transition={{
        type: 'spring',
        mass: 0.35,
        stiffness: 75,
        duration: 5.3,
      }}
    >
      <h1>Pagina 1</h1>
      <p>
        {/* <AnchorLink
          to="#sotto"
          onClick={() => console.log('click')}
        >
          Vai giù
        </AnchorLink> */}
      </p>
      <p style={{ fontSize: '30px' }}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
        porta, urna nec placerat luctus, leo quam sagittis ante, eget tincidunt
        odio libero vel est. Cras nec neque imperdiet sem blandit pharetra.
        Vivamus ultricies pharetra bibendum. Sed in luctus felis, ut commodo
        leo. Duis metus nisi, condimentum sollicitudin diam dignissim, euismod
        varius tortor. Donec facilisis, velit sed congue vulputate, nisi nisi
        sollicitudin metus, a lobortis eros ex at purus. Aliquam erat volutpat.
        Mauris volutpat in risus id scelerisque. Proin convallis libero sed quam
        malesuada sagittis. Proin quis mi vel leo rhoncus pulvinar. Praesent id
        sagittis dolor. Maecenas sollicitudin, justo in dapibus pharetra, ligula
        metus varius nisl, et rhoncus nibh velit in eros. Donec posuere
        vestibulum elit, gravida pretium justo interdum in. Nunc ac laoreet
        tortor, vitae dapibus turpis. Phasellus tempus velit ac orci
        ullamcorper, ut porta nunc blandit. Nunc cursus diam et leo faucibus
        rhoncus. Aenean dapibus tempor ligula id ultricies. Donec libero ex,
        interdum ac lacus ornare, gravida posuere est. Maecenas ut diam eget
        tellus pulvinar pharetra. Nunc sit amet nisl vel neque fringilla
        lobortis. Integer nec sapien tempor, congue erat eu, varius leo. Cras
        cursus, dui in gravida suscipit, tellus felis rutrum turpis, sed
        condimentum metus orci vel ipsum. Sed a nunc porta, malesuada mi ut,
        fringilla lacus. Donec pretium orci id lectus tincidunt placerat.
        Maecenas bibendum feugiat pellentesque. Mauris id orci eu ipsum
        efficitur viverra vel elementum est. Nullam tristique, ipsum sed
        scelerisque ultrices, dolor erat tempus est, id congue nisi ante quis
        orci. In lobortis lorem neque, non facilisis enim malesuada ut. Aenean
        et sapien tincidunt nisi congue scelerisque. Quisque faucibus nunc
        nulla, at elementum nulla venenatis vel. Proin tincidunt, mauris sit
        amet scelerisque venenatis, tellus nunc mollis nisl, in congue augue
        arcu et lacus. Vivamus commodo felis vitae lorem tempus bibendum. Nullam
        sed sem in est rhoncus molestie id at massa. Proin turpis mi,
        scelerisque ac dictum et, accumsan in purus. Nullam eu commodo augue. In
        in lectus eget lorem efficitur tempus. Pellentesque malesuada vel lacus
        non congue. Aliquam hendrerit urna sed libero volutpat, non eleifend
        ipsum rutrum. Donec ut pulvinar ligula. Fusce sagittis pulvinar dui ut
        tempus. Curabitur massa metus, varius at finibus tempor, pulvinar ut
        erat. Vivamus non enim id ligula tristique vestibulum. Phasellus quis
        purus commodo, venenatis ante et, rhoncus nunc. Ut tristique porttitor
        risus vel dapibus. Nam est sem, mollis eget aliquet ut, tristique quis
        tortor. Nunc velit est, viverra sollicitudin felis lacinia, rutrum
        commodo sem. Curabitur ut convallis purus, quis pretium libero. Aenean
        sed dictum purus. In suscipit felis ipsum, non posuere risus laoreet ut.
        Ut non erat lobortis, ornare felis ac, rutrum mi. In ipsum ligula,
        faucibus vitae faucibus quis, tempor vitae massa. Interdum et malesuada
        fames ac ante ipsum primis in faucibus. In massa ante, vulputate ut eros
        in, interdum ultrices sapien. Vestibulum mauris leo, vulputate sit amet
        purus sed, gravida ornare lorem. Mauris non faucibus tortor. Etiam id
        est a enim eleifend condimentum. Mauris non erat in enim malesuada
        sagittis. Donec viverra felis lacus, a accumsan ex blandit quis.
        Maecenas sodales ullamcorper libero, eget luctus risus convallis in.
        Maecenas pharetra tincidunt justo, vel egestas magna suscipit eleifend.
        Integer in lorem ipsum. In faucibus arcu non justo varius malesuada ac
        sit amet est. Phasellus pulvinar odio nec turpis rhoncus, consequat
        lacinia elit dictum. Nunc ut ultricies nisl. Quisque in sollicitudin
        tellus. In porta scelerisque vestibulum. Morbi eu urna ut diam efficitur
        facilisis. In non ante et turpis malesuada hendrerit. Donec id turpis
        non justo commodo pharetra sed ac risus. Integer tristique elit in
        egestas sollicitudin. Fusce a placerat tellus, quis rutrum magna. Donec
        lacinia sem nunc, at suscipit ipsum laoreet nec. Curabitur ultrices
        ullamcorper est, id porta erat ullamcorper nec. Proin neque sem, iaculis
        at lectus id, ornare posuere elit. Morbi consectetur luctus metus, quis
        maximus justo rhoncus eu. Vivamus porta neque sit amet justo
        consectetur, a venenatis enim finibus. Nam luctus nisi eros, quis porta
        augue eleifend et. Morbi sit amet vestibulum enim. Donec gravida maximus
        metus a facilisis. Nullam nibh risus, mollis ut sapien id, tristique
        mollis sapien. Curabitur sed magna ligula. Ut sit amet velit at orci
        vulputate tristique. Duis id rhoncus risus. Duis rhoncus urna sit amet
        felis sollicitudin, accumsan hendrerit nunc ullamcorper. In vitae augue
        neque. Ut placerat dolor metus, quis porta elit ornare ac. Suspendisse
        vestibulum orci et mauris mattis, eu condimentum sapien accumsan. In
        risus quam, consequat ut neque et, efficitur vehicula nisl. Curabitur
        hendrerit, augue congue gravida ornare, sem mi sodales tortor, bibendum
        vestibulum lacus mauris sit amet nulla. Interdum et malesuada fames ac
        ante ipsum primis in faucibus. Integer quis interdum nisi, accumsan
        feugiat purus. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        Morbi justo orci, viverra vel consequat vitae, sagittis ac massa.
        Vivamus luctus nisi quis ex blandit rutrum. Curabitur sed sollicitudin
        lorem, vitae tempus mauris. Suspendisse cursus semper erat, sed posuere
        massa vestibulum vel. Sed vulputate aliquam sagittis. Sed rutrum urna in
        turpis tristique, pulvinar tristique massa fermentum. Suspendisse
        sagittis metus ipsum, quis scelerisque tortor porttitor id. Nullam eget
        lacus hendrerit, rhoncus mauris sed, viverra magna. Curabitur semper,
        metus nec mattis vehicula, nisl ex laoreet dui, eget porttitor metus
        turpis in justo. Proin vestibulum justo a euismod commodo. Nunc
        efficitur dolor dolor, eu cursus libero imperdiet et. Pellentesque eget
        viverra libero. Aliquam eros nibh, tempor vitae sagittis at, pharetra ut
        sem. Morbi tempor consectetur enim, vitae ullamcorper est. Mauris
        laoreet, risus fermentum imperdiet ultrices, leo justo hendrerit leo,
        eget pulvinar felis lacus ut eros. Suspendisse eget placerat justo, ut
        sagittis magna. Nam laoreet ultricies finibus. Orci varius natoque
        penatibus et magnis dis parturient montes, nascetur ridiculus mus.
        Quisque viverra sed tellus quis consequat. Morbi eu ipsum porta, aliquet
        arcu ut, sodales risus. Curabitur rhoncus magna aliquet tellus auctor,
        in tempor neque eleifend. Pellentesque feugiat pretium tristique. Sed eu
        pretium nulla. Sed eget neque sit amet leo commodo porta eu et sapien.
        Ut rhoncus pretium eleifend. Cras viverra magna id leo consectetur, ut
        blandit nisi porta. Donec sodales sit amet mi eu consectetur. In orci
        odio, facilisis et vestibulum sed, porta sit amet ligula. Nulla eu
        fermentum metus. Vestibulum pellentesque libero at nunc suscipit
        aliquam. Praesent dictum, magna eu aliquam dapibus, dui eros convallis
        turpis, consequat posuere felis neque a neque. Vivamus laoreet imperdiet
        dui, sit amet faucibus velit pellentesque eget. Aliquam ac sapien
        maximus, imperdiet augue id, suscipit justo. Nulla nunc nisi,
        sollicitudin id tempus non, ultrices ut libero. Morbi ut suscipit felis.
        Aliquam vitae dolor id mi efficitur finibus. Nunc vitae auctor sem.
        Fusce erat dui, eleifend vel erat et, condimentum gravida erat.
        Pellentesque auctor rhoncus arcu vitae suscipit. Cras vitae sollicitudin
        felis. Sed nibh sem, ultrices et pulvinar sit amet, auctor eu metus.
        Integer pretium commodo venenatis. Sed blandit feugiat mauris. Morbi
        ornare mauris sapien. Aenean convallis suscipit nulla non gravida.
        Maecenas faucibus sem metus, eget cursus massa accumsan in. Vestibulum
        in augue et ex ultrices rhoncus sit amet in lacus. Fusce ac blandit
        felis. Maecenas ut rhoncus purus. Pellentesque non justo nec nisl
        convallis aliquet at ut nisi. Morbi pharetra, ligula mollis sollicitudin
        semper, dui massa vulputate lectus, sed suscipit dui erat at nibh. Proin
        dui felis, sollicitudin ac neque vel, porttitor sagittis metus. Nam
        iaculis, nisi sit amet aliquam condimentum, ipsum dui iaculis turpis,
        vitae convallis lorem neque in enim. Nam interdum enim ac erat faucibus
        venenatis. Fusce vitae mi sed neque viverra varius. Quisque lorem nunc,
        placerat ac nibh vel, euismod tristique mi. Phasellus sodales mauris
        fermentum odio accumsan dapibus. Pellentesque habitant morbi tristique
        senectus et netus et malesuada fames ac turpis egestas. Sed sed metus at
        dolor vehicula dignissim vel sit amet metus. Integer hendrerit in massa
        convallis suscipit. Maecenas tincidunt imperdiet porta. Class aptent
        taciti sociosqu ad litora torquent per conubia nostra, per inceptos
        himenaeos. Pellentesque lobortis vulputate sagittis. Nullam dolor lacus,
        vulputate congue metus id, efficitur ultricies lorem. Integer ut
        elementum sem. Ut massa ipsum, interdum ut blandit at, sollicitudin non
        ipsum. Phasellus mattis rhoncus velit, at feugiat lacus rutrum eget. Sed
        finibus placerat urna ut aliquam. Fusce sed luctus felis. Aliquam erat
        volutpat. Nulla quis lorem at libero lobortis pharetra tempor nec
        ligula. Suspendisse condimentum eu orci vitae posuere. Sed commodo ut
        nunc in ultricies. Nunc pharetra lobortis lorem, eleifend mollis mi.
        Aenean quis enim lacinia, sollicitudin libero at, gravida nibh. Donec
        nec turpis rutrum, luctus nisl faucibus, molestie ligula. Phasellus sit
        amet leo felis. Sed varius erat at justo laoreet, quis sagittis orci
        mollis. Sed luctus leo ac egestas tincidunt. Nulla bibendum ex justo, in
        euismod nisl lobortis et. Duis scelerisque odio erat, ut accumsan nisi
        varius a.
      </p>
      <div id="sotto">
        <Link to="/pagina2">Vai a pagina 2</Link>
      </div>
    </motion.div>
  )
}

// class Pagina1 extends React.Component {
//   render() {
//     return (
//       <motion.div
//         key="/"
//         initial={{ opacity: 0, x: -200 }}
//         animate={{ opacity: 1, x: 0 }}
//         exit={{ opacity: 0, x: -400 }}
//         transition={{
//           type: 'spring',
//           mass: 0.35,
//           stiffness: 75,
//           duration: 5.3,
//         }}
//         {...ulScrollRestoration}
//       >
//         <h1>Pagina 1</h1>
//         <p>
//           <AnchorLink
//             to="/pagina1#sotto"
//             title="Scopri i miei progetti"
//             onClick={() => console.log('click')}
//           >
//             Vai giù
//           </AnchorLink>
//         </p>
//         <p style={{ fontSize: '30px' }}>
//           Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
//           porta, urna nec placerat luctus, leo quam sagittis ante, eget
//           tincidunt odio libero vel est. Cras nec neque imperdiet sem blandit
//           pharetra. Vivamus ultricies pharetra bibendum. Sed in luctus felis, ut
//           commodo leo. Duis metus nisi, condimentum sollicitudin diam dignissim,
//           euismod varius tortor. Donec facilisis, velit sed congue vulputate,
//           nisi nisi sollicitudin metus, a lobortis eros ex at purus. Aliquam
//           erat volutpat. Mauris volutpat in risus id scelerisque. Proin
//           convallis libero sed quam malesuada sagittis. Proin quis mi vel leo
//           rhoncus pulvinar. Praesent id sagittis dolor. Maecenas sollicitudin,
//           justo in dapibus pharetra, ligula metus varius nisl, et rhoncus nibh
//           velit in eros. Donec posuere vestibulum elit, gravida pretium justo
//           interdum in. Nunc ac laoreet tortor, vitae dapibus turpis. Phasellus
//           tempus velit ac orci ullamcorper, ut porta nunc blandit. Nunc cursus
//           diam et leo faucibus rhoncus. Aenean dapibus tempor ligula id
//           ultricies. Donec libero ex, interdum ac lacus ornare, gravida posuere
//           est. Maecenas ut diam eget tellus pulvinar pharetra. Nunc sit amet
//           nisl vel neque fringilla lobortis. Integer nec sapien tempor, congue
//           erat eu, varius leo. Cras cursus, dui in gravida suscipit, tellus
//           felis rutrum turpis, sed condimentum metus orci vel ipsum. Sed a nunc
//           porta, malesuada mi ut, fringilla lacus. Donec pretium orci id lectus
//           tincidunt placerat. Maecenas bibendum feugiat pellentesque. Mauris id
//           orci eu ipsum efficitur viverra vel elementum est. Nullam tristique,
//           ipsum sed scelerisque ultrices, dolor erat tempus est, id congue nisi
//           ante quis orci. In lobortis lorem neque, non facilisis enim malesuada
//           ut. Aenean et sapien tincidunt nisi congue scelerisque. Quisque
//           faucibus nunc nulla, at elementum nulla venenatis vel. Proin
//           tincidunt, mauris sit amet scelerisque venenatis, tellus nunc mollis
//           nisl, in congue augue arcu et lacus. Vivamus commodo felis vitae lorem
//           tempus bibendum. Nullam sed sem in est rhoncus molestie id at massa.
//           Proin turpis mi, scelerisque ac dictum et, accumsan in purus. Nullam
//           eu commodo augue. In in lectus eget lorem efficitur tempus.
//           Pellentesque malesuada vel lacus non congue. Aliquam hendrerit urna
//           sed libero volutpat, non eleifend ipsum rutrum. Donec ut pulvinar
//           ligula. Fusce sagittis pulvinar dui ut tempus. Curabitur massa metus,
//           varius at finibus tempor, pulvinar ut erat. Vivamus non enim id ligula
//           tristique vestibulum. Phasellus quis purus commodo, venenatis ante et,
//           rhoncus nunc. Ut tristique porttitor risus vel dapibus. Nam est sem,
//           mollis eget aliquet ut, tristique quis tortor. Nunc velit est, viverra
//           sollicitudin felis lacinia, rutrum commodo sem. Curabitur ut convallis
//           purus, quis pretium libero. Aenean sed dictum purus. In suscipit felis
//           ipsum, non posuere risus laoreet ut. Ut non erat lobortis, ornare
//           felis ac, rutrum mi. In ipsum ligula, faucibus vitae faucibus quis,
//           tempor vitae massa. Interdum et malesuada fames ac ante ipsum primis
//           in faucibus. In massa ante, vulputate ut eros in, interdum ultrices
//           sapien. Vestibulum mauris leo, vulputate sit amet purus sed, gravida
//           ornare lorem. Mauris non faucibus tortor. Etiam id est a enim eleifend
//           condimentum. Mauris non erat in enim malesuada sagittis. Donec viverra
//           felis lacus, a accumsan ex blandit quis. Maecenas sodales ullamcorper
//           libero, eget luctus risus convallis in. Maecenas pharetra tincidunt
//           justo, vel egestas magna suscipit eleifend. Integer in lorem ipsum. In
//           faucibus arcu non justo varius malesuada ac sit amet est. Phasellus
//           pulvinar odio nec turpis rhoncus, consequat lacinia elit dictum. Nunc
//           ut ultricies nisl. Quisque in sollicitudin tellus. In porta
//           scelerisque vestibulum. Morbi eu urna ut diam efficitur facilisis. In
//           non ante et turpis malesuada hendrerit. Donec id turpis non justo
//           commodo pharetra sed ac risus. Integer tristique elit in egestas
//           sollicitudin. Fusce a placerat tellus, quis rutrum magna. Donec
//           lacinia sem nunc, at suscipit ipsum laoreet nec. Curabitur ultrices
//           ullamcorper est, id porta erat ullamcorper nec. Proin neque sem,
//           iaculis at lectus id, ornare posuere elit. Morbi consectetur luctus
//           metus, quis maximus justo rhoncus eu. Vivamus porta neque sit amet
//           justo consectetur, a venenatis enim finibus. Nam luctus nisi eros,
//           quis porta augue eleifend et. Morbi sit amet vestibulum enim. Donec
//           gravida maximus metus a facilisis. Nullam nibh risus, mollis ut sapien
//           id, tristique mollis sapien. Curabitur sed magna ligula. Ut sit amet
//           velit at orci vulputate tristique. Duis id rhoncus risus. Duis rhoncus
//           urna sit amet felis sollicitudin, accumsan hendrerit nunc ullamcorper.
//           In vitae augue neque. Ut placerat dolor metus, quis porta elit ornare
//           ac. Suspendisse vestibulum orci et mauris mattis, eu condimentum
//           sapien accumsan. In risus quam, consequat ut neque et, efficitur
//           vehicula nisl. Curabitur hendrerit, augue congue gravida ornare, sem
//           mi sodales tortor, bibendum vestibulum lacus mauris sit amet nulla.
//           Interdum et malesuada fames ac ante ipsum primis in faucibus. Integer
//           quis interdum nisi, accumsan feugiat purus. Lorem ipsum dolor sit
//           amet, consectetur adipiscing elit. Morbi justo orci, viverra vel
//           consequat vitae, sagittis ac massa. Vivamus luctus nisi quis ex
//           blandit rutrum. Curabitur sed sollicitudin lorem, vitae tempus mauris.
//           Suspendisse cursus semper erat, sed posuere massa vestibulum vel. Sed
//           vulputate aliquam sagittis. Sed rutrum urna in turpis tristique,
//           pulvinar tristique massa fermentum. Suspendisse sagittis metus ipsum,
//           quis scelerisque tortor porttitor id. Nullam eget lacus hendrerit,
//           rhoncus mauris sed, viverra magna. Curabitur semper, metus nec mattis
//           vehicula, nisl ex laoreet dui, eget porttitor metus turpis in justo.
//           Proin vestibulum justo a euismod commodo. Nunc efficitur dolor dolor,
//           eu cursus libero imperdiet et. Pellentesque eget viverra libero.
//           Aliquam eros nibh, tempor vitae sagittis at, pharetra ut sem. Morbi
//           tempor consectetur enim, vitae ullamcorper est. Mauris laoreet, risus
//           fermentum imperdiet ultrices, leo justo hendrerit leo, eget pulvinar
//           felis lacus ut eros. Suspendisse eget placerat justo, ut sagittis
//           magna. Nam laoreet ultricies finibus. Orci varius natoque penatibus et
//           magnis dis parturient montes, nascetur ridiculus mus. Quisque viverra
//           sed tellus quis consequat. Morbi eu ipsum porta, aliquet arcu ut,
//           sodales risus. Curabitur rhoncus magna aliquet tellus auctor, in
//           tempor neque eleifend. Pellentesque feugiat pretium tristique. Sed eu
//           pretium nulla. Sed eget neque sit amet leo commodo porta eu et sapien.
//           Ut rhoncus pretium eleifend. Cras viverra magna id leo consectetur, ut
//           blandit nisi porta. Donec sodales sit amet mi eu consectetur. In orci
//           odio, facilisis et vestibulum sed, porta sit amet ligula. Nulla eu
//           fermentum metus. Vestibulum pellentesque libero at nunc suscipit
//           aliquam. Praesent dictum, magna eu aliquam dapibus, dui eros convallis
//           turpis, consequat posuere felis neque a neque. Vivamus laoreet
//           imperdiet dui, sit amet faucibus velit pellentesque eget. Aliquam ac
//           sapien maximus, imperdiet augue id, suscipit justo. Nulla nunc nisi,
//           sollicitudin id tempus non, ultrices ut libero. Morbi ut suscipit
//           felis. Aliquam vitae dolor id mi efficitur finibus. Nunc vitae auctor
//           sem. Fusce erat dui, eleifend vel erat et, condimentum gravida erat.
//           Pellentesque auctor rhoncus arcu vitae suscipit. Cras vitae
//           sollicitudin felis. Sed nibh sem, ultrices et pulvinar sit amet,
//           auctor eu metus. Integer pretium commodo venenatis. Sed blandit
//           feugiat mauris. Morbi ornare mauris sapien. Aenean convallis suscipit
//           nulla non gravida. Maecenas faucibus sem metus, eget cursus massa
//           accumsan in. Vestibulum in augue et ex ultrices rhoncus sit amet in
//           lacus. Fusce ac blandit felis. Maecenas ut rhoncus purus. Pellentesque
//           non justo nec nisl convallis aliquet at ut nisi. Morbi pharetra,
//           ligula mollis sollicitudin semper, dui massa vulputate lectus, sed
//           suscipit dui erat at nibh. Proin dui felis, sollicitudin ac neque vel,
//           porttitor sagittis metus. Nam iaculis, nisi sit amet aliquam
//           condimentum, ipsum dui iaculis turpis, vitae convallis lorem neque in
//           enim. Nam interdum enim ac erat faucibus venenatis. Fusce vitae mi sed
//           neque viverra varius. Quisque lorem nunc, placerat ac nibh vel,
//           euismod tristique mi. Phasellus sodales mauris fermentum odio accumsan
//           dapibus. Pellentesque habitant morbi tristique senectus et netus et
//           malesuada fames ac turpis egestas. Sed sed metus at dolor vehicula
//           dignissim vel sit amet metus. Integer hendrerit in massa convallis
//           suscipit. Maecenas tincidunt imperdiet porta. Class aptent taciti
//           sociosqu ad litora torquent per conubia nostra, per inceptos
//           himenaeos. Pellentesque lobortis vulputate sagittis. Nullam dolor
//           lacus, vulputate congue metus id, efficitur ultricies lorem. Integer
//           ut elementum sem. Ut massa ipsum, interdum ut blandit at, sollicitudin
//           non ipsum. Phasellus mattis rhoncus velit, at feugiat lacus rutrum
//           eget. Sed finibus placerat urna ut aliquam. Fusce sed luctus felis.
//           Aliquam erat volutpat. Nulla quis lorem at libero lobortis pharetra
//           tempor nec ligula. Suspendisse condimentum eu orci vitae posuere. Sed
//           commodo ut nunc in ultricies. Nunc pharetra lobortis lorem, eleifend
//           mollis mi. Aenean quis enim lacinia, sollicitudin libero at, gravida
//           nibh. Donec nec turpis rutrum, luctus nisl faucibus, molestie ligula.
//           Phasellus sit amet leo felis. Sed varius erat at justo laoreet, quis
//           sagittis orci mollis. Sed luctus leo ac egestas tincidunt. Nulla
//           bibendum ex justo, in euismod nisl lobortis et. Duis scelerisque odio
//           erat, ut accumsan nisi varius a.
//         </p>
//         <div id="sotto">
//           <Link to="/pagina2">Vai a pagina 2</Link>
//         </div>
//       </motion.div>
//     )
//   }
// }

export default Pagina1
